import React, { useEffect, useState } from 'react';
import './SuccessBooking.scss';
import { Link, navigate } from "gatsby";
import { SERVICE_TYPES } from '../../enums/serviceTypes';
import SuccessIcon from '../../assets/images/icons/success-3.svg'
import { FiCalendar, FiHome, FiMapPin, FiVideo } from 'react-icons/fi';

const SuccessBooking = (props) => {
	const [type1, setType1] = useState(true);
	const [visitType, setVisitType] = useState();
	const [date, setDate] = useState();
	const [address, setAddress] = useState();
	const [city, setCity] = useState('');

	useEffect(() => {
		const query = new URLSearchParams(props.location.search);
		const type = query.get("book-type");
		const visitTypeQ = query.get("visit-type");
		const dateQ = query.get("date");
		const addressQ = query.get("address");
		if (!type) {
			navigate('/');
		} else {
			if (type === SERVICE_TYPES.Flyttstädning || type === SERVICE_TYPES.Storstädning) {
				setType1(false);
			}
			setVisitType(visitTypeQ);
			setDate(dateQ);
			setAddress(addressQ);
		}

		const city = localStorage.getItem('city');

		if (city === 'linkoping') {
			setCity('linkoping');
		}
		//eslint-disable-next-line
	}, []);

	return (
		<div className="SuccessBooking">
			<div className="content">
				<h1><SuccessIcon />{type1 ? 'Tack för att du har bokat ett startmöte!' : 'Tack för din bokningsförfrågan!'}</h1>
				<p>{type1 ? 'I samband med startmötet går vi tillsammans igenom dina behov och presentererar ett upplägg för din städning.' : 'Vi återkopplar med ett bekräftelsemejl så snart vi hunnit titta i våra scheman för att se om önskat datum finns ledigt. '}</p>
				{type1 && (
					<div className="firstTypeItems">
						{visitType && <div className="firstTypeItem">{visitType === "digital" ? <><FiVideo />"Digitalt hembesök"</> : <><FiHome />Personligt hembesök</>}</div>}
						{date && <div className="firstTypeItem"><FiCalendar />{date}</div>}
						{address && <div className="firstTypeItem"><FiMapPin />{address}</div>}
					</div>
				)}
				{type1 ? <>
					<p>Vi är proffs på städning och ser fram emot att få ta hand om ditt hem!</p>
					<p>Har du frågor eller funderingar? Hör av dig till oss på {city === '' ? `info@dreamclean.nu eller 036 - 14 15 00` : `linkoping@dreamclean.nu eller 013-15 16 00`}</p>
					<p>Med vänliga hälsningar,<br /> Dreamclean</p>
				</> : <div class="secondTypeTexts"><p>Visste du att Dreamclean är Jönköpings mest rekommenderade städfirma? <a target="_blanko" href="https://www.reco.se/dreamclean-sweden-ab">Klicka här</a> för att se vad några av våra kunder säger.</p> </div>}
			</div>
			<Link
				type="button"
				to="/"
				className="success-btn"
			// onClick={onClick}
			>
				Tillbaka till startsida
			</Link>
		</div>
	);
};

export default SuccessBooking;